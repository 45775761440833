.container-cover {
  margin: 0;
  padding: 0;
}

.image-container {
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.image-container h1 {
  color: white;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: bold;
  left: 60%;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 8%;
}
