.container-image {
  position: relative;
}

.container-image img {
  width: 100%;
  height: auto;
}

.container-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
