.box-footer-url-page2 {
  height: 10%;
  left: 78%;
  margin: 0;
  opacity: 0%;
  padding: 0;
  position: absolute;
  top: 89%;
  width: 20%;
}
