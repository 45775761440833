.box-footer-url-page15 {
  height: 10%;
  left: 74.5%;
  margin: 0;
  opacity: 0%;
  padding: 0;
  position: absolute;
  top: 88%;
  width: 20%;
}
