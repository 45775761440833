.box-body-url-page12 {
  height: 10%;
  left: 74.5%;
  margin: 0;
  opacity: 0%;
  padding: 0;
  position: absolute;
  top: 71%;
  width: 20%;
}
