.box-footer-url-page13 {
  height: 10%;
  left: 65.5%;
  margin: 0;
  opacity: 0%;
  padding: 0;
  position: absolute;
  top: 88%;
  width: 29%;
}
