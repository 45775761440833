.box-footer-url-page4 {
  height: 10%;
  left: 76%;
  margin: 0;
  opacity: 0%;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 89%;
  width: 20%;
}
